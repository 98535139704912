import * as i0 from '@angular/core';
import { forwardRef, Directive, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const CONTROL_ACCESSOR$1 = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AtomsControlDirective),
  multi: true
};
const fileInput$1 = 'ATOM-FILE-UPLOAD';
const checkboxGroup = 'ATOM-CHECKBOX-GROUP';
const checkbox = 'ATOM-CHECKBOX';
const _switch$1 = 'ATOM-SWITCH';
const radioGroup = 'ATOM-RADIO-GROUP';
const autocomplete$1 = 'ATOM-AUTOCOMPLETE';
class AtomsControlDirective {
  constructor(element, renderer) {
    this.element = element;
    this.renderer = renderer;
    this.blockEventsReceived = {
      time: 500,
      finished: false
    };
    setTimeout(() => {
      this.blockEventsReceived.finished = true;
    }, this.blockEventsReceived.time);
  }
  registerOnTouched(fn) {
    this.onTouch = fn;
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  writeValue(value) {
    if (this.isFileInput()) {
      return;
    }
    if (this.blockEventsReceived.finished) {
      this.handleInput(value, false);
    } else {
      // Assignment when form is created (to avoid crazy init events from components). Workaround:
      if (!this.isEmpty(value)) {
        setTimeout(() => {
          this.handleInput(value, false);
        }, this.blockEventsReceived.time + 50);
      }
    }
  }
  setDisabledState(isDisabled) {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }
  /****************************************/
  /************* HostListeners ************/
  /****************************************/
  onBlur(event) {
    this.onTouch();
  }
  onClick(event) {
    if (this.shouldOnTouchBeFiredOnClick()) {
      this.onTouch();
    }
  }
  onModelValueChanged(event) {
    if (event.detail.isTriggeredByUser) {
      if (this.isSwitch()) {
        this.handleInput(event.target['modelValue'].checked, true);
      } else {
        this.handleInput(event.target['modelValue'], true);
      }
    }
  }
  onFileUpload(event) {
    this.onModelChange(event.detail.files);
  }
  /****************************************/
  /*************** Methods ****************/
  /****************************************/
  handleInput(value, onModelChange) {
    if (this.shouldUpdateInput(value)) {
      this.setValue(value, onModelChange);
    }
  }
  setValue(value, onModelChange) {
    this.value = this.copy(value);
    if (!onModelChange) {
      if (this.isSwitch()) {
        this.renderer.setProperty(this.element.nativeElement, 'checked', value);
      } else if (this.isAutocomplete() && this.isEmpty(value)) {
        this.element.nativeElement.clear();
      } else if (this.isCheckboxGroup()) {
        this.setValueToCheckboxGroupNativeElement(value);
      } else {
        this.setValueToNativeElement(value);
      }
    } else if (onModelChange && this.isDefined(this.onModelChange)) {
      this.onModelChange(value);
    }
  }
  setValueToCheckboxGroupNativeElement(value) {
    for (let option of this.element.nativeElement.children) {
      if (option.nodeName === checkbox) {
        this.renderer.setProperty(option, 'checked', value.includes(option.value));
      }
    }
  }
  setValueToNativeElement(value) {
    this.renderer.setProperty(this.element.nativeElement, 'modelValue', Array.isArray(value) ? JSON.stringify(value) : value);
  }
  shouldUpdateInput(value) {
    return this.isDefined(value) && this.isNewValue(value);
  }
  isNewValue(value) {
    if (value instanceof File) {
      return true;
    } else if (Array.isArray(value)) {
      return JSON.stringify(value) !== JSON.stringify(this.value);
    } else if (typeof value === 'object') {
      return JSON.stringify(value) !== JSON.stringify(this.value);
    }
    return this.value !== value;
  }
  isDefined(value) {
    return typeof value !== 'undefined';
  }
  isEmpty(value) {
    // Not defined
    if (!this.isDefined(value)) {
      return true;
    }
    // Empty array
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    // Null or checkbox to false
    if (typeof value === 'object') {
      if (value === null) {
        return true;
      }
      if (this.isDefined(value.checked) && value.checked === false) {
        return true;
      }
    }
    // Empty string
    if (value === '') {
      return true;
    }
    return false;
  }
  copy(value) {
    if (Array.isArray(value)) {
      return [...value];
    } else if (typeof value === 'object') {
      return Object.assign({}, value);
    }
    return value;
  }
  shouldOnTouchBeFiredOnClick() {
    return this.isFileInput() || this.isCheckboxGroup() || this.isCheckbox() || this.isRadioGroup() || this.isSwitch();
  }
  isFileInput() {
    return fileInput$1 === this.element.nativeElement.nodeName;
  }
  isCheckboxGroup() {
    return checkboxGroup === this.element.nativeElement.nodeName;
  }
  isCheckbox() {
    return checkbox === this.element.nativeElement.nodeName;
  }
  isSwitch() {
    return _switch$1 === this.element.nativeElement.nodeName;
  }
  isRadioGroup() {
    return radioGroup === this.element.nativeElement.nodeName;
  }
  isAutocomplete() {
    return autocomplete$1 === this.element.nativeElement.nodeName;
  }
}
AtomsControlDirective.ɵfac = function AtomsControlDirective_Factory(t) {
  return new (t || AtomsControlDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
AtomsControlDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AtomsControlDirective,
  selectors: [["", "atomsControl", ""]],
  hostBindings: function AtomsControlDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("blur", function AtomsControlDirective_blur_HostBindingHandler($event) {
        return ctx.onBlur($event);
      })("click", function AtomsControlDirective_click_HostBindingHandler($event) {
        return ctx.onClick($event);
      })("model-value-changed", function AtomsControlDirective_model_value_changed_HostBindingHandler($event) {
        return ctx.onModelValueChanged($event);
      })("file-upload", function AtomsControlDirective_file_upload_HostBindingHandler($event) {
        return ctx.onFileUpload($event);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([CONTROL_ACCESSOR$1])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AtomsControlDirective, [{
    type: Directive,
    args: [{
      selector: '[atomsControl]',
      providers: [CONTROL_ACCESSOR$1]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    onBlur: [{
      type: HostListener,
      args: ['blur', ['$event']]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onModelValueChanged: [{
      type: HostListener,
      args: ['model-value-changed', ['$event']]
    }],
    onFileUpload: [{
      type: HostListener,
      args: ['file-upload', ['$event']]
    }]
  });
})();
const CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AtomsControlDirective2),
  multi: true
};
const disabled = 'disabled';
const input = 'ATOMS-INPUT';
const fileInput = 'ATOMS-FILE-UPLOAD';
const _switch = 'ATOMS-SWITCH';
const autocomplete = 'ATOMS-AUTOCOMPLETE';
class AtomsControlDirective2 {
  constructor(element, renderer) {
    this.element = element;
    this.renderer = renderer;
    this.firstAssignment = true;
    // console.log('constructor - element', this.element);
    // console.log('constructor - renderer', this.renderer);
  }
  registerOnTouched(fn) {
    this.onTouch = fn;
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  writeValue(value) {
    // console.log('writeValue - value', value);
    if (this.firstAssignment) {
      this.firstAssignment = false;
      if (this.isAutocomplete() && this.isEmpty(value)) {
        return;
      }
    }
    this.handleInput(value, false);
  }
  setDisabledState(isDisabled) {
    if (isDisabled) {
      this.renderer.setAttribute(this.element.nativeElement, disabled, disabled);
    } else {
      this.renderer.removeAttribute(this.element.nativeElement, disabled);
    }
  }
  /****************************************/
  /************* HostListeners ************/
  /****************************************/
  onBlur(event) {
    // console.log('blur fires onTouch');
    this.onTouch();
  }
  onClick(event) {
    if (this.shouldOnTouchBeFiredOnClick()) {
      // console.log('onClick fires onTouch');
      this.onTouch();
    }
  }
  onChange(event) {
    if (this.shouldReactToChangeEvent()) {
      let value;
      // console.log('onChange', event.target);
      if (this.isSwitch()) {
        value = this.transformUndefinedValue(event.target.checked);
      } else {
        value = this.transformUndefinedValue(event.target.value);
      }
      this.handleInput(value, true);
    }
  }
  onInput(event) {
    if (this.shouldReactToInputEvent()) {
      // console.log('onInput', event.target);
      event.target.value = this.transformUndefinedValue(event.target.value);
      this.handleInput(event.target.value, true);
    }
  }
  onFileUpload(event) {
    this.onModelChange(this.getAcceptedFiles(event));
  }
  /****************************************/
  /*************** Methods ****************/
  /****************************************/
  handleInput(value, changeModel) {
    // console.log('handleInput - ', value, 'changeModel', changeModel);
    if (this.shouldUpdateInput(value)) {
      // console.log('handleInput - shouldUpdateInput');
      this.setValue(value, changeModel);
    }
  }
  setValue(value, changeModel) {
    this.value = this.copy(value);
    if (!changeModel) {
      if (this.isSwitch()) {
        this.renderer.setProperty(this.element.nativeElement, 'checked', value);
      } else if (this.isAutocomplete() && this.isEmpty(value)) {
        this.element.nativeElement.clear();
      } else {
        // console.log('setValue - setValueToNativeElement');
        this.setValueToNativeElement(value);
      }
    } else if (this.isDefined(this.onModelChange)) {
      this.onModelChange(value);
    }
  }
  setValueToNativeElement(value) {
    // console.log('setValueToNativeElement', value);
    const finalValue = Array.isArray(value) ? JSON.stringify(value) : value;
    this.renderer.setProperty(this.element.nativeElement, 'value', finalValue);
  }
  getAcceptedFiles(event) {
    let acceptedFiles = [];
    if (this.isDefined(event.detail.files)) {
      for (let i = 0; i < event.detail.files.length; i++) {
        if (this.element.nativeElement.errors[i] === null) {
          acceptedFiles.push(event.detail.files[i]);
        }
      }
    }
    return acceptedFiles;
  }
  shouldUpdateInput(value) {
    return this.isDefined(value) && this.isNewValue(value);
  }
  isNewValue(value) {
    if (typeof value === 'object') {
      return JSON.stringify(value) !== JSON.stringify(this.value);
    }
    return this.value !== value;
  }
  isDefined(value) {
    return typeof value !== 'undefined';
  }
  isEmpty(value) {
    // Not defined
    if (!this.isDefined(value)) {
      return true;
    }
    // Empty array
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    // Null
    if (typeof value === 'object') {
      if (value === null) {
        return true;
      }
      // More conditions to come here...
    }
    // Empty string
    if (value === '') {
      return true;
    }
    return false;
  }
  copy(value) {
    if (Array.isArray(value)) {
      return [...value];
    } else if (typeof value === 'object') {
      return Object.assign({}, value);
    }
    return value;
  }
  /**
   * This method exists because of some Atoms components
   * not triggering the blur event.
   */
  shouldOnTouchBeFiredOnClick() {
    return false;
  }
  shouldReactToChangeEvent() {
    return !this.isInput();
  }
  shouldReactToInputEvent() {
    return this.isInput();
  }
  transformUndefinedValue(value) {
    if (this.isDefined(value)) {
      return value;
    }
    if (this.isAutocomplete()) {
      return '';
    }
    if (this.isSwitch()) {
      return false;
    }
    return value;
  }
  isInput() {
    return input === this.element.nativeElement.nodeName;
  }
  isFileInput() {
    return fileInput === this.element.nativeElement.nodeName;
  }
  isSwitch() {
    return _switch === this.element.nativeElement.nodeName;
  }
  isAutocomplete() {
    return autocomplete === this.element.nativeElement.nodeName;
  }
}
AtomsControlDirective2.ɵfac = function AtomsControlDirective2_Factory(t) {
  return new (t || AtomsControlDirective2)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
AtomsControlDirective2.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AtomsControlDirective2,
  selectors: [["", "atomsControl2", ""]],
  hostBindings: function AtomsControlDirective2_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("blur", function AtomsControlDirective2_blur_HostBindingHandler($event) {
        return ctx.onBlur($event);
      })("click", function AtomsControlDirective2_click_HostBindingHandler($event) {
        return ctx.onClick($event);
      })("change", function AtomsControlDirective2_change_HostBindingHandler($event) {
        return ctx.onChange($event);
      })("input", function AtomsControlDirective2_input_HostBindingHandler($event) {
        return ctx.onInput($event);
      })("file-upload", function AtomsControlDirective2_file_upload_HostBindingHandler($event) {
        return ctx.onFileUpload($event);
      });
    }
  },
  features: [i0.ɵɵProvidersFeature([CONTROL_ACCESSOR])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AtomsControlDirective2, [{
    type: Directive,
    args: [{
      selector: '[atomsControl2]',
      providers: [CONTROL_ACCESSOR]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    onBlur: [{
      type: HostListener,
      args: ['blur', ['$event']]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onChange: [{
      type: HostListener,
      args: ['change', ['$event']]
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }],
    onFileUpload: [{
      type: HostListener,
      args: ['file-upload', ['$event']]
    }]
  });
})();
class AngularModule {}
AngularModule.ɵfac = function AngularModule_Factory(t) {
  return new (t || AngularModule)();
};
AngularModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AngularModule
});
AngularModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularModule, [{
    type: NgModule,
    args: [{
      declarations: [AtomsControlDirective, AtomsControlDirective2],
      imports: [],
      exports: [AtomsControlDirective, AtomsControlDirective2]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularModule, AtomsControlDirective, AtomsControlDirective2 };
